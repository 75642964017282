import React from "react";
import { makeStyles } from "@mui/styles";
import rev3ctLogo from "../assets/imgs/Rev3ctLogo.png";
import { Typography, Button, Grid } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import lombada from "../assets/imgs/lombada1.svg";
import bg from "../assets/imgs/banner.png";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    position: "relative",
    minHeight: "400px",
    background: '#000000',
    [theme.breakpoints.up(700)]: {
      height: "100vh",
      minHeight: "665px",
      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url('${bg}') no-repeat`,
      backgroundSize: "100%",
      backgroundPosition: "50% 5%",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    background: "rgba(0, 0, 0, 0.75)",
    padding: "24px 27px",
    [theme.breakpoints.down(700)]: {
      display: 'none'
    },
    "& img": {
      [theme.breakpoints.down("sm")]: {
        display: "block",
        margin: "auto",
      },
      width: "220px",
    },
  },
  headerMobile: {
    [theme.breakpoints.up(700)]: {
      display: 'none'
    },

    display: "flex",
    justifyContent: "space-between",
    background: "rgba(0, 0, 0, 0.75)",
    padding: "24px 27px",
    "& img": {
      display: "block",
      margin: "auto",
    },
  },
  mobileImage: {
    [theme.breakpoints.up(700)]: {
      display: 'none'
    },

    width: '100%',
    objectFit: 'cover',
  },
  centerText: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    [theme.breakpoints.up("md")]: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  },
  lombadaContainer: {
    position: "relative",
    background: '#000000',

    [theme.breakpoints.down("sm")]: {
      overflow: "hidden",
    },
    "& img": {
      width: "100%",
      background: '#000000',
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        display: "block",
        margin: "auto",
      },
    },
  },
  lombadaText: {
    background: "#fff",
    height: "56px",
  },
  videoHolder: {
    height: "100%",
    padding: "0 16px",
    background: '#000000'
  },
  revolution: {
    background: "#000",
    padding: "40px 16px",
    [theme.breakpoints.up("md")]: {
      padding: "40px 130px",
    },
  },
}));

const Explanation = () => {
  const classes = useStyles();
  const mobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <>
      <div style={{
        height: '100%',
        background: '#000000'
      }}>
        <header className={classes.headerMobile}>
          <img src={rev3ctLogo} alt="Rev3ct" />
        </header>
        <img src={bg} className={classes.mobileImage} alt="Rev3ct" />
      </div>
      <div className={classes.root}>
        <header className={classes.header}>
          <img src={rev3ctLogo} alt="Rev3ct" />
        </header>
        <div className={classes.centerText}>
          <Typography
            align="center"
            sx={{
              fontFamily: "drukwide",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: {
                xs: "18px",
                sm: "21px",
                lg: "36px",
              },
              lineHeight: {
                xs: "25px",
                lg: "38px",
              },
              textAlign: "center",
              color: "transparent",
              WebkitTextStroke: "1px #D55112",
              opacity: "0.25",
            }}
          >
            VIVA UMA JORNADA DE
          </Typography>
          <Typography
            align="center"
            sx={{
              fontFamily: "drukwide",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: {
                xs: "18px",
                sm: "21px",
                lg: "36px",
              },
              lineHeight: {
                xs: "25px",
                lg: "38px",
              },
              textAlign: "center",
              color: "transparent",
              WebkitTextStroke: "1px #D55112",
              opacity: "0.5",
            }}
          >
            VIVA UMA JORNADA DE
          </Typography>
          <Typography
            align="center"
            sx={{
              fontFamily: "drukwide",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: {
                xs: "18px",
                sm: "21px",
                lg: "36px",
              },
              lineHeight: {
                xs: "25px",
                lg: "38px",
              },
              textAlign: "center",
              color: "#D55112",
            }}
          >
            VIVA UMA JORNADA DE
          </Typography>
          <Typography
            align="center"
            sx={{
              fontFamily: "drukwide",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: {
                xs: "18px",
                sm: "21px",
                lg: "46px",
              },
              lineHeight: {
                xs: "25px",
                lg: "55px",
              },
              textAlign: "center",
              color: "#fff",
              marginBottom: "16px",
            }}
          >
            TRANSFORMAÇÃO
          </Typography>
          <Typography
            sx={{
              fontFamily: "poppins",
              fontStyle: "normal",
              fontSize: "16px",
              textAlign: "center",
              color: "#fff",
              marginBottom: "40px",
              padding: "0px 24px",
              "& span": {
                fontWeight: "500",
              },
            }}
          >
            Construiremos o <span>melhor</span> e mais <span>inspirador</span>{" "}
            caminho para a sua transformação através do{" "}
            <span>treinamento físico</span>.
          </Typography>
          <a
            href="https://api.whatsapp.com/send?phone=5511943295462&text=Gostaria%20de%20saber%20mais%20sobre%20os%20planos."
            target="_blank"
            style={{ textDecoration: "none" }} rel="noreferrer"
          >
            <Button
              variant="contained"
              sx={{
                fontFamily: "drukwide",
                color: "#fff",
                background: "#D55112",
                borderRadius: "18px",
                display: "block",
                margin: "auto",
                "&:hover": { background: "#D55112" },
              }}
            >
              QUERO SER ALUNO
            </Button>
          </a>
        </div>
      </div>
      <div className={classes.lombadaContainer}>
        <div className={classes.lombadaText}>
          <Typography
            sx={{
              fontFamily: "drukwide",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "20px",
              textAlign: "center",
              color: "#1F1F1F",
              marginBottom: "16px",
              lineHeight: "56px",
            }}
          >
            Assista ao vídeo!
          </Typography>
        </div>
        <img src={lombada} alt="" />
      </div>
      <Grid container>
        <Grid item xs={12} lg={6}>
          <div className={classes.videoHolder}>
            <iframe
              width="100%"
              height={mobile ? "200" : "360"}
              src="https://www.youtube.com/embed/6UWw4rcEeA0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </Grid>
        <Grid item xs={12} lg={6}>
          <div className={classes.revolution}>
            <Typography
              sx={{
                fontFamily: "drukwide",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "24px",
                textAlign: {
                  xs: "center",
                  lg: "left",
                },
                color: "#fff",
                marginBottom: "8px",
              }}
            >
              NOSSA <br /> REVOLUÇÃO
            </Typography>
            <Typography
              sx={{
                fontFamily: "drukwide",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "15px",
                textAlign: {
                  xs: "center",
                  lg: "left",
                },
                color: "#D55112",
                marginBottom: "16px",
              }}
            >
              O que nos faz únicos
            </Typography>
            <Typography
              sx={{
                fontFamily: "poppins",
                fontStyle: "normal",
                fontSize: "16px",
                lineHeight: "25px",
                textAlign: {
                  xs: "center",
                  lg: "left",
                },
                color: "#fff",
                marginBottom: "40px",
                padding: {
                  xs: "0px 24px",
                  lg: "0px",
                },
                "& span": {
                  fontWeight: "500",
                },
              }}
            >
              Sua visão sobre o treinamento físico{" "}
              <span>nunca mais será a mesma</span>. Você nunca viu algo
              parecido.
            </Typography>
            <Typography
              sx={{
                fontFamily: "poppins",
                fontStyle: "normal",
                fontSize: "16px",
                lineHeight: "25px",
                textAlign: {
                  xs: "center",
                  lg: "left",
                },
                color: "#fff",
                marginBottom: "40px",
                padding: {
                  xs: "0px 24px",
                  lg: "0px",
                },
                "& span": {
                  fontWeight: "500",
                },
              }}
            >
              Agora você tem a chance de fazer parte de uma nova{" "}
              <span>revolução</span>.
            </Typography>
            <Typography
              sx={{
                fontFamily: "poppins",
                fontStyle: "normal",
                fontSize: "16px",
                lineHeight: "25px",
                textAlign: {
                  xs: "center",
                  lg: "left",
                },
                color: "#fff",
                marginBottom: "40px",
                padding: {
                  xs: "0px 24px",
                  lg: "0px",
                },
                "& span": {
                  fontWeight: "500",
                },
              }}
            >
              Junte-se a nós e alcance sua <span>transformação</span>.
            </Typography>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Explanation;
