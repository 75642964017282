import { Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slider from "react-slick";
import useMediaQuery from "@mui/material/useMediaQuery";

import quoteMarks from "../assets/imgs/quotes.png";
import star from "../assets/imgs/star.png";
import giovanna from "../assets/imgs/giovanna.png";
import mayara from "../assets/imgs/mayara.png";
import cristina from "../assets/imgs/cristina.png";
import bruna from "../assets/imgs/bruna.png";
import meire from "../assets/imgs/meire.png";
import bruno from "../assets/imgs/bruno.png";
import larissa from "../assets/imgs/larissa.png";
import nathan from "../assets/imgs/nathan.png";
import { Arrow } from "./Arrow";

const quotes = [
  {
    text: "Havia largado, desanimado dos exercícios, perdido a vontade mas vocês me puxaram de volta aos trilhos. Sou extremamente grata pelo o que fazem e pela forma que cuidam de todo o time. Vocês mudam vidas! Vocês são humanos e se conectam de verdade com todo o time.",
    name: "Mayara Batista",
    photo: mayara,
  },
  {
    text: "A Revect me fez gostar de academia e dos exercícios, além de sempre me motivar.",
    name: "Gioavanna Munhai",
    photo: giovanna,
  },
  {
    text: "Eu já tinha tentado academia academia sozinha, programas on-line e nada funcionou. Só tive melhoras incríveis para o meu problema de coluna quando comecei a treinar com a Revect. O meu objetivo foi alcançado.",
    name: "Cristina Oliveira",
    photo: cristina,
  },
  {
    text: "Nunca tive tão bons resultados como agora com os treinos da Revect! Mais motivação, mais suporte, mais embasamento. Toda diferença.",
    name: "Bruna Barbieri",
    photo: bruna,
  },
  {
    text: "Quando iniciei nessa jornada, busquei mudar. Antes não gostava de exercícios, e graças a vocês hoje não consigo viver sem. Vocês acreditaram em mim quando nem eu mesma acreditava. Obrigada de verdade!",
    name: "Meire Meloni",
    photo: meire,
  },
  {
    text: "A Revect faz um trabalho faz um trabalho impecável. O time é muito acessível e estão sempre disponíveis para qualquer coisa que precisamos. Sempre em busca de entregar o melhor trabalho possível.",
    name: "Bruno Mariano",
    photo: bruno,
  },
  {
    text: "Só gostaria de agradecer vocês. Vocês salvaram minha vida.",
    name: "Larissa Padovan",
    photo: larissa,
  },
  {
    text: "Quero só agradecer. Nunca evoluí tanto! Isso graças aos conselhos que vocês me deram.",
    name: "Nathan Wender",
    photo: nathan,
  },
];

const useStyles = makeStyles({
  root: {
    paddingTop: "80px",
    padding: "80px 25px 0px 25px",
  },
  quotes: {
    display: "block",
    margin: "auto",
    width: "72px",
  },
  stars: {
    display: "flex",
    justifyContent: "center",
  },

  photo: {
    marginTop: "8px",
    display: "block",
    margin: "auto",
    borderRadius: "50%",
    border: "2px solid #D55112",
  },
  container: {
    padding: "16px",
  },
});

const Testimonials = () => {
  const classes = useStyles();
  const mobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: mobile ? 1 : 4,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <Arrow next />,
    prevArrow: <Arrow />
  };

  return (
    <div className={classes.root}>
      <Typography
        sx={{
          fontFamily: "drukwide",
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "24px",
          textAlign: "center",
          color: "#fff",
          marginBottom: "16px",
        }}
      >
        VEJA O QUE NOSSOS ALUNOS ESTÃO DIZENDO:
      </Typography>

      <Slider {...settings}>
        {quotes.map((i, index) => (
          <div key={index} className={classes.container}>
            <Card sx={{ background: "#1F1F1F", borderRadius: "24px" }}>
              <CardContent>
                <img src={quoteMarks} alt="" className={classes.quotes} />
                <br />
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    fontStyle: "normal",
                    fontSize: "16px",
                    textAlign: "center",
                    color: "#fff",
                    marginBottom: "40px",
                    padding: "0px 24px",
                    "& span": {
                      fontWeight: "500",
                    },
                  }}
                >
                  {i.text}
                </Typography>
                <div className={classes.stars}>
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                </div>
                <div className={classes.photoContainer}>
                  <div className={classes.absolute}>
                    <img src={i.photo} className={classes.photo} alt={i.name} />
                    <Typography
                      sx={{
                        fontFamily: "drukwide",
                        fontStyle: "normal",
                        fontWeight: "bold",
                        fontSize: "16px",
                        textAlign: "center",
                        color: "#fff",
                      }}
                    >
                      {i.name}
                    </Typography>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Testimonials;
