import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slider from "react-slick";
import useMediaQuery from "@mui/material/useMediaQuery";

import plano from "../assets/imgs/plano.png";
import avaliacao from "../assets/imgs/avaliacao.png";
import integracao from "../assets/imgs/integracao.png";
import { Arrow } from "./Arrow";

const useStyles = makeStyles({
  root: {
    paddingTop: "80px",
    padding: "80px 16px 80px 16px",
  },
  hr: {
    width: "50%",
    border: "1px solid #D55112",
  },
  cardTitle: {
    display: "flex",
    justifyContent: "space-between",
  },
  container: {
    padding: "16px",
    width: '90%'
  },
});

const How = () => {
  const classes = useStyles();
  const mobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: mobile ? 1 : 3,
    slidesToScroll: 1,
    arrows: mobile,
    nextArrow: <Arrow next />,
    prevArrow: <Arrow />
  };

  return (
    <div className={classes.root}>
      <Typography
        sx={{
          fontFamily: "drukwide",
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "24px",
          textAlign: "center",
          color: "#fff",
          marginBottom: "16px",
        }}
      >
        COMO FUNCIONA
      </Typography>
      <hr className={classes.hr} />
      <Typography
        sx={{
          fontFamily: "poppins",
          fontStyle: "normal",
          fontSize: "20px",
          lineHeight: "25px",
          textAlign: "center",
          color: "#fff",
          marginBottom: "40px",
          padding: "0px 24px",
          "& span": {
            fontWeight: "500",
          },
        }}
      >
        Veja como iniciar sua <span>jornada conosco</span>
      </Typography>
      <Slider {...settings}>
        <div className={classes.container}>
          <Card
            sx={{
              borderRadius: "56px 16px 16px 16px",
              background: "#1F1F1F",
              padding: "24px 16px",
              height: "350px",
            }}
          >
            <CardContent>
              <img src={plano} alt="" />
              <div className={classes.cardTitle}>
                <Typography
                  sx={{
                    fontFamily: "drukwide",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: {
                      xs: "18px",
                      sm: "28px",
                    },
                    color: "#fff",
                  }}
                >
                  Plano
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "gotham",
                    fontStyle: "normal",
                    fontSize: {
                      xs: "22px",
                      sm: "32px",
                    },
                    color: "#fff",
                    opacity: "0.35",
                  }}
                >
                  01
                </Typography>
              </div>
              <hr />
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontStyle: "normal",
                  fontSize: {
                    xs: "12px",
                    sm: "16px",
                  },
                  textAlign: "center",
                  color: "#fff",
                  padding: "0px 24px",
                  "& span": {
                    fontWeight: "500",
                  },
                }}
              >
                Escolha o plano que <span>mais combina com você</span>, ou se
                tiver dúvida,
                <span>converse conosco</span> que orientaremos você sobre{" "}
                <span>melhor possibilidade</span>.
              </Typography>
            </CardContent>
          </Card>
        </div>
        <div className={classes.container}>
          <Card
            sx={{
              borderRadius: "16px",
              background: "#1F1F1F",
              padding: "24px 16px",
              height: "350px",
            }}
          >
            <CardContent>
              <img src={avaliacao} alt="" />
              <div className={classes.cardTitle}>
                <Typography
                  sx={{
                    fontFamily: "drukwide",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: {
                      xs: "18px",
                      sm: "28px",
                    },
                    color: "#fff",
                    marginBottom: "16px",
                  }}
                >
                  Avaliação
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "gotham",
                    fontStyle: "normal",
                    fontSize: {
                      xs: "22px",
                      sm: "32px",
                    },
                    color: "#fff",
                    opacity: "0.35",
                  }}
                >
                  02
                </Typography>
              </div>
              <hr />
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontStyle: "normal",
                  fontSize: {
                    xs: "12px",
                    sm: "16px",
                  },
                  textAlign: "center",
                  color: "#fff",
                  padding: "0px 24px",
                  "& span": {
                    fontWeight: "500",
                  },
                }}
              >
                Para oferecermos uma <span>experiência única</span> de
                treinamento queremos lhe
                <span> conhecer melhor</span> através de uma avaliação.
              </Typography>
            </CardContent>
          </Card>
        </div>
        <div className={classes.container}>
          <Card
            sx={{
              borderRadius: "16px 56px 16px 16px",
              background: "#D55112",
              padding: "24px 16px",
              height: "350px",
            }}
          >
            <CardContent>
              <img src={integracao} alt="" />
              <div className={classes.cardTitle}>
                <Typography
                  sx={{
                    fontFamily: "drukwide",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: {
                      xs: "18px",
                      sm: "28px",
                    },
                    textAlign: "center",
                    color: "#fff",
                    marginBottom: "16px",
                  }}
                >
                  Integração
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "gotham",
                    fontStyle: "normal",
                    fontSize: {
                      xs: "22px",
                      sm: "32px",
                    },
                    color: "#fff",
                    opacity: "0.35",
                  }}
                >
                  03
                </Typography>
              </div>
              <hr />
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontStyle: "normal",
                  fontSize: {
                    xs: "12px",
                    sm: "16px",
                  },
                  textAlign: "center",
                  color: "#fff",
                  padding: "0px 24px",
                  "& span": {
                    fontWeight: "500",
                  },
                }}
              >
                Esse é o momento que a sua experiência de{" "}
                <span>transformação começa</span>. Daqui para frente, você
                viverá uma <span>realidade inspiradora</span>.
              </Typography>
            </CardContent>
          </Card>
        </div>
      </Slider>
    </div>
  );
};

export default How;
