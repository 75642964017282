import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export function Arrow(props) {
  const { className, onClick, next, style } = props;
  return <>
    {next ? <ArrowForwardIosIcon
      className={className}
      style={{
        color: '#d55111',
        margin: '0 0.5rem',
        fontSize: 40,
        zIndex: 9999,
        ...style
      }}
      onClick={onClick}
    /> : <ArrowBackIosIcon
    className={className}
    style={{
      color: '#d55111',
      margin: '0 1rem',
      fontSize: 40,
      zIndex: 9999,
      ...style
    }}
    onClick={onClick}
  />}
  </>
}