import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import felipe from "../assets/imgs/felipe.png";
import leticia from "../assets/imgs/leticia.png";
import alexandre from "../assets/imgs/alexandre.png";
import lombada from "../assets/imgs/lombada2.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "80px 16px",
    [theme.breakpoints.up("md")]: {
      padding: "80px 64px",
    },
  },
  hr: {
    width: "50%",
    border: "1px solid #D55112",
  },
  mentors: {
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      justifyContent: "space-evenly",
    },
  },
  mentorContainer: {
    [theme.breakpoints.up("lg")]: {
      width: "348px",
      "&:nth-child(2)": {
        marginTop: "175px",
      },
      "&:nth-child(3)": {
        marginTop: "300px",
      },
    },
  },
  mentorPhoto: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "348px",
      display: "block",
      margin: "auto",
    },
  },
  lombadaContainer: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      overflow: "hidden",
    },
    "& img": {
      width: "70%",
      display: "block",
      margin: "auto",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        display: "block",
        margin: "auto",
      },
    },
  },
  whiteDiv: {
    background: "#fff",
    padding: "16px 16px 8px 16px",
  },
}));

const Mentors = () => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.root}>
        <Grid container>
          <Grid
            item
            xs={12}
            lg={6}
            sx={{ padding: { xs: "0px", lg: "0px 100px" } }}
          >
            <Typography
              sx={{
                fontFamily: "drukwide",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "24px",
                textAlign: "center",
                color: "#fff",
                marginBottom: "16px",
              }}
            >
              CONHEÇA SEUS MENTORES
            </Typography>
            <hr className={classes.hr} />
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            sx={{ padding: { xs: "0px", lg: "0px 100px" } }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontStyle: "normal",
                fontSize: "20px",
                lineHeight: "25px",
                textAlign: "center",
                color: "#fff",
                marginBottom: "40px",
                padding: "0px 24px",
                "& span": {
                  fontWeight: "500",
                },
              }}
            >
              Responsáveis pelo treinamento de{" "}
              <span>atletas de bodybuilding</span>, <span>modelos</span> e{" "}
              <span>acima de tudo, </span>todos que buscam uma{" "}
              <span>grande transformação.</span>
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.mentors}>
          <div className={classes.mentorContainer}>
            <img
              className={classes.mentorPhoto}
              src={felipe}
              alt="Felipe Rev3ct"
            />
            <Card sx={{ background: "#1F1F1F", borderRadius: "24px" }}>
              <CardContent>
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    fontStyle: "normal",
                    fontSize: "16px",
                    textAlign: "center",
                    color: "#fff",
                    padding: "24px",
                    "& span": {
                      fontWeight: "500",
                    },
                  }}
                >
                  <span>Especialista em</span> Fisiologia, Bioquímica e Nutrição
                  do treinamento desportivo. (LABEX –UNICAMP) e{" "}
                  <span>graduado em</span> Educação Física pela
                  <span> UNICAMP</span>.
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div className={classes.mentorContainer}>
            <img
              className={classes.mentorPhoto}
              src={leticia}
              alt="Letícia Rev3ct"
            />
            <Card sx={{ background: "#1F1F1F", borderRadius: "24px" }}>
              <CardContent>
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    fontStyle: "normal",
                    fontSize: "16px",
                    textAlign: "center",
                    color: "#fff",
                    padding: "0px 24px",
                    "& span": {
                      fontWeight: "500",
                    },
                  }}
                >
                  <span>Especialista em</span> Treinamento Resistido e
                  Biomêcanica. <span>Formada em </span>
                  Educação Física pela Escola Superior de Educação Física em
                  Jundiaí- <span>ESEF</span>.
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div className={classes.mentorContainer}>
            <img
              className={classes.mentorPhoto}
              src={alexandre}
              alt="Alexandre Rev3ct"
            />
            <Card sx={{ background: "#1F1F1F", borderRadius: "24px" }}>
              <CardContent>
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    fontStyle: "normal",
                    fontSize: "16px",
                    textAlign: "center",
                    color: "#fff",
                    padding: "0px 24px",
                    "& span": {
                      fontWeight: "500",
                    },
                  }}
                >
                  <span>Especialista em</span> Hipertrofia/Emagrecimento e
                  Biomecânica da Musculação. <span>Graduado em</span> Educação
                  Física pela Escola Superior de Educação Física em Jundiaí –{" "}
                  <span>ESEF</span>.
                </Typography>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
      <div className={classes.lombadaContainer}>
        <div className={classes.whiteDiv}>
          <Typography
            sx={{
              fontFamily: "drukwide",
              fontStyle: "normal",
              fontWeight: "bold",
              textAlign: "center",
              color: "#1F1F1F",
              marginBottom: "16px",
              lineHeight: "56px",
              fontSize: {
                sm: "1rem",
                md: "1.5rem"
              }
            }}
          >
            Você não estará só.
          </Typography>
          <Typography
            sx={{
              fontFamily: "poppins",
              fontStyle: "normal",
              fontSize: "16px",
              textAlign: "center",
              color: "#1F1F1F",
              marginBottom: "40px",
              padding: "0px 24px",
              "& span": {
                fontWeight: "500",
              },
            }}
          >
            Terá nosso apoio e suporte em todos momentos dessa jornada. Seremos
            os responsáveis pela sua segurança nos treinos e amparo psicológico
            nos momentos de dificuldade.
          </Typography>
        </div>
        <img src={lombada} alt="" />
      </div>
    </div>
  );
};

export default Mentors;
