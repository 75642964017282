import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "80px 16px 16px 16px",
  },
  faqContainer: {
    [theme.breakpoints.up("md")]: {
      padding: "0px 64px",
    },
  },
}));

const Faq = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography
        sx={{
          fontFamily: "drukwide",
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "18px",
          textAlign: "center",
          color: "#D55112",
          marginBottom: "16px",
          textShadow: "0px 0px 5px #D55112",
        }}
      >
        TRANSFORME SUA VIDA. VENHA FAZER PARTE DO NOSSO TIME.
      </Typography>
      <a
        href="https://api.whatsapp.com/send?phone=5511943295462&text=Gostaria%20de%20saber%20mais%20sobre%20os%20planos."
        target="_blank"
        style={{ textDecoration: "none" }}
        rel="noreferrer"
      >
        <Button
          sx={{
            fontFamily: "drukwide",
            fontSize: "14px",
            color: "#fff",
            background: "#D55112",
            borderRadius: "18px",
            display: "block",
            margin: "auto",
            marginBottom: "80px",
            "&:hover": { background: "#D55112" },
          }}
        >
          QUERO ENTRAR AGORA
        </Button>
      </a>
      <Typography
        sx={{
          fontFamily: "drukwide",
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "24px",
          textAlign: "center",
          color: "#fff",
          marginBottom: "24px",
        }}
      >
        Perguntas <br /> frequentes
      </Typography>
      <div className={classes.faqContainer}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
            sx={{
              background: "#1F1F1F",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontStyle: "normal",
                fontSize: "16px",
                color: "#fff",
                "& span": {
                  fontWeight: "500",
                },
              }}
            >
              <span>
                1. Qual a diferença do R3V Plan em relação aos outros planos?
              </span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              background: "#262626",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontStyle: "normal",
                fontSize: "16px",
                color: "#fff",
                "& span": {
                  fontWeight: "500",
                },
              }}
            >
              O R3V Plan é um plano de <strong>consultoria trimestral.</strong> Ele é <strong>≈válido somente</strong>
              para os planos <strong>ORANGE</strong> e <strong>BLACK</strong>, ou seja, tanto para consultoria online, 
              quanto para consultoria presencial. Portanto, <strong>é possível optar pelo R3V Plan, 
              para consultoria online</strong>, ou o R3V Plan, <strong>para consultoria presencial.</strong> É o único 
              plano com um <strong>tempo de vigência maior</strong>, sendo este 12 semanas. No R3V Plan também 
              <strong>há produtos exclusivos</strong>, como: camiseta e galão personalizado. Também haverá <strong>mais 
              um retorno com o profissional</strong> durante o tempo de vigência do plano; portanto, 
              haverá uma nova consulta (presencial ou online) para avaliação e ajustes no seu treino.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
            sx={{
              background: "#1F1F1F",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontStyle: "normal",
                fontSize: "16px",
                color: "#fff",
                "& span": {
                  fontWeight: "500",
                },
              }}
            >
              <span>
                2. Qual a diferença entre o Plano White, Orange e Black?
              </span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              background: "#262626",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontStyle: "normal",
                fontSize: "16px",
                color: "#fff",
                "& span": {
                  fontWeight: "500",
                },
              }}
            >
              <strong>O Plano White é um plano por formulário.</strong> Através do preenchimento de um 
              formulário disponibilizado pelos nossos profissionais, será desenvolvido um treino 
              personalizado. <strong>O tempo de vigência do Plano White</strong> é de 45 dias. <strong>O Plano Orange é um 
              plano de consultoria online.</strong> Através de uma avaliação e alinhamento dos objetivos de 
              forma remota (por uma vídeo-chamada), o profissional irá desenvolver um treino que 
              atenda todos os objetivos, preferências e particularidades do indivíduo.<strong>O tempo de 
              vigência do plano Orange</strong> é de 8 semanas. <strong>O Plano Black é um plano de consultoria presencial.</strong>
              Além do alinhamento dos objetivos e particularidades em uma conversa presencial (em nosso consultório), 
              <strong>há também uma avaliação física completa</strong> para aferir percentual de gordura corporal, medidas gerais, 
              e uma avaliação postural. <strong>O tempo de vigência do Plano Black</strong> é de 8 semanas
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
            sx={{
              background: "#1F1F1F",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontStyle: "normal",
                fontSize: "16px",
                color: "#fff",
                "& span": {
                  fontWeight: "500",
                },
              }}
            >
              <span>
                3. Quais são os benefícios que todo R3Vmember terá?
              </span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              background: "#262626",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontStyle: "normal",
                fontSize: "16px",
                color: "#fff",
                "& span": {
                  fontWeight: "500",
                },
              }}
            >
              Todo o <strong>R3Vmember terá</strong>: acesso ao aplicativo de treinos com todos os vídeos explicativos dos exercícios, 
              acesso à comunidade REVECT, acesso ao método Treino em Casa, participação em desafios com premiações, 
              conteúdos exclusivos, suporte integral e personalizado de todo o time de Sucesso do Cliente, acompanhamento 
              profissional próximo e humanizado. Além disso tudo,<strong>todo o time REVECT tem um compromisso em fazer da 
              jornada de cada R3Vmember</strong>, uma jornada <strong>única</strong> e de <strong>transformação.</strong>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ border: "none" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
            sx={{
              background: "#1F1F1F",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontStyle: "normal",
                fontSize: "16px",
                color: "#fff",
                "& span": {
                  fontWeight: "500",
                },
              }}
            >
              <span>
                4. O método é para qualquer pessoa? Mesmo para quem nunca
                treinou?
              </span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              background: "#262626",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontStyle: "normal",
                fontSize: "16px",
                color: "#fff",
                "& span": {
                  fontWeight: "500",
                },
              }}
            >
              Sim! O método é para todos que querem viver uma experiência
              transformadora pelo exercício físico. Independente das
              individualidades, nível de treinamento, dificuldades, limitações,
              rotina corrida, etc… nosso método serve para todos!
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
            sx={{
              background: "#1F1F1F",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontStyle: "normal",
                fontSize: "16px",
                color: "#fff",
                "& span": {
                  fontWeight: "500",
                },
              }}
            >
              <span>
                5. Funciona para quem não tem muito tempo para treinar?
              </span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              background: "#262626",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontStyle: "normal",
                fontSize: "16px",
                color: "#fff",
                "& span": {
                  fontWeight: "500",
                },
              }}
            >
              Com certeza funciona! Temos inúmeros casos de pessoas com
              curtíssimo tempo para treinar e com excelentes resultados. Sempre
              deixamos claro que independente da sua condição, situação, nós
              traçaremos o plano ideal para sua experiência com a Revect.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
            sx={{
              background: "#1F1F1F",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontStyle: "normal",
                fontSize: "16px",
                color: "#fff",
                "& span": {
                  fontWeight: "500",
                },
              }}
            >
              <span>
                6. Se eu estiver com dúvida, dificuldade ou problema em relação
                ao treino, vocês resolverão?
              </span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              background: "#262626",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontStyle: "normal",
                fontSize: "16px",
                color: "#fff",
                "& span": {
                  fontWeight: "500",
                },
              }}
            >
              Sim! Todas as dúvidas, dificuldades, problemas de aderência e até
              mesmo uma situação pessoal que esteja impactando negativamente sua
              vida e seu processo por aqui, estaremos prontos a ouvir e ajudar.
              Afinal, o amparo é um de nossos pilares.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
            sx={{
              background: "#1F1F1F",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontStyle: "normal",
                fontSize: "16px",
                color: "#fff",
                "& span": {
                  fontWeight: "500",
                },
              }}
            >
              <span>
                7. Não gosto de academia, há possibilidade de treinar em casa?
              </span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              background: "#262626",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontStyle: "normal",
                fontSize: "16px",
                color: "#fff",
                "& span": {
                  fontWeight: "500",
                },
              }}
            >
              Com certeza há possibilidade de treinar em casa! Boa parcela dos
              membros da Revect treinam em casa e conquistam excelentes
              resultados, sendo que, muitas vezes não têm um vasto repertório de
              materiais para treino. Contudo, nós elaboramos um plano
              extremamente funcional e exclusivo para atender você em treinos na
              academia ou em domicílio.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
            sx={{
              background: "#1F1F1F",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontStyle: "normal",
                fontSize: "16px",
                color: "#fff",
                "& span": {
                  fontWeight: "500",
                },
              }}
            >
              <span>
                8. E se eu falhar, faltar em treinos, cair no desânimo.
                enfrentar problemas que dificultam minha aderência ao plano,
                serei amparado?
              </span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              background: "#262626",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontStyle: "normal",
                fontSize: "16px",
                color: "#fff",
                "& span": {
                  fontWeight: "500",
                },
              }}
            >
              Sem dúvida alguma será amparado! Entendemos que falhar, desanimar,
              pensar em desistir é uma ocorrência natural. Não somos um time que
              simplesmente planeja um excelente treino, mas sim uma empresa
              humanizada que entende todos os membros como pessoas que enfrentam
              problemas, dificuldades, questões emocionais e somos realmente
              parceiros de todos para ouvir, amparar, ajudar e inspirar. Você
              sempre poderá contar conosco!
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
            sx={{
              background: "#1F1F1F",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontStyle: "normal",
                fontSize: "16px",
                color: "#fff",
                "& span": {
                  fontWeight: "500",
                },
              }}
            >
              <span>
                9. Se eu desconhecer o exercício e como o executar, terei
                assistência?
              </span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              background: "#262626",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontStyle: "normal",
                fontSize: "16px",
                color: "#fff",
                "& span": {
                  fontWeight: "500",
                },
              }}
            >
              Sim! Na nossa plataforma temos todos os exercícios muito bem
              explicados em curtos vídeos. Entretanto, mesmo havendo dúvidas na
              execução de algum movimento, conte conosco para resolver isso.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
            sx={{
              background: "#1F1F1F",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontStyle: "normal",
                fontSize: "16px",
                color: "#fff",
                "& span": {
                  fontWeight: "500",
                },
              }}
            >
              <span>
                10. Terei de fato um treino personalizado, exclusivo para mim?
              </span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              background: "#262626",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontStyle: "normal",
                fontSize: "16px",
                color: "#fff",
                "& span": {
                  fontWeight: "500",
                },
              }}
            >
              Sem dúvidas você terá! O nosso pilar de excelência é justamente
              para traçar a melhor jornada para você nessa experiência conosco.
              Portanto, como se trata da sua jornada, ela deve ser única e
              totalmente exclusiva.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
            sx={{
              background: "#1F1F1F",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontStyle: "normal",
                fontSize: "16px",
                color: "#fff",
                "& span": {
                  fontWeight: "500",
                },
              }}
            >
              <span>
                11. Terei assistência, amparo e cuidado em todo momento que eu
                precisar mesmo?
              </span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              background: "#262626",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontStyle: "normal",
                fontSize: "16px",
                color: "#fff",
                "& span": {
                  fontWeight: "500",
                },
              }}
            >
              Em todo o momento! Desde de seu primeiro contato conosco prezamos
              por construir sua experiência de transformação a partir desses 3
              pilares. Seremos a mão que te guia no melhor caminho, a mão que te
              sustenta nele, e a que te impulsiona na jornada.Em todo o momento!
              Desde de seu primeiro contato conosco prezamos por construir sua
              experiência de transformação a partir desses 3 pilares. Seremos a
              mão que te guia no melhor caminho, a mão que te sustenta nele, e a
              que te impulsiona na jornada.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default Faq;
