import React from "react";
import WhatYouWillReceiveDesktopImg from "../assets/imgs/o-que-voce-recebera-desk.png";
import { makeStyles } from "@mui/styles";
import { Card, CardContent, Typography, Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#191818",
    "& img": {
      width: "100%",
    },
  },
  container: {
    padding: "16px 16px 32px 16px",
    [theme.breakpoints.up("md")]: {
      width: "750px",
      display: "block",
      margin: "auto",
    },
  },
  top: {
    background: "#000000",
    padding: "16px 4.3px",
    [theme.breakpoints.up("md")]: {
      padding: "16px 8px",
    },
  },
  bottom: {
    background: "#262626",
    padding: "16px 0px",
  },
}));

const WhatYouWillReceive = () => {
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <img src={WhatYouWillReceiveDesktopImg} alt="O que você receberá" />
      <div className={classes.container}>
        <Card
          sx={{ borderRadius: "24px", border: "none", background: "#262626" }}
        >
          <CardContent
            sx={{ padding: "0px", "&:last-child": { padding: "0px" } }}
          >
            <div className={classes.top}>
              <Typography
                sx={{
                  fontFamily: "drukwide",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: {
                    xs: "36px",
                    md: "60px",
                  },
                  lineHeight: "43px",
                  textAlign: "center",
                  color: "#D55112",
                }}
              >
                + DE 800 VIDAS
              </Typography>
              <Typography
                sx={{
                  fontFamily: "drukwide",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  paddingTop: "8px",
                  fontSize: {
                    xs: "20px",
                    md: "28px",
                  },
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                transformadas através do treinamento.
              </Typography>
            </div>
            <div className={classes.bottom}>
              <Typography
                sx={{
                  fontFamily: "drukwide",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: {
                    xs: "20px",
                    md: "28px",
                  },
                  paddingBottom: "8px",
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                A SUA PODE SER A PRÓXIMA.
              </Typography>
              <a
                href="https://api.whatsapp.com/send?phone=5511943295462&text=Gostaria%20de%20saber%20mais%20sobre%20os%20planos."
                target="_blank"
                style={{ textDecoration: "none" }} rel="noreferrer"
              >
                <Button
                  sx={{
                    fontFamily: "drukwide",
                    fontSize: "14px",
                    color: "#fff",
                    background: "#D55112",
                    borderRadius: "18px",
                    display: "block",
                    margin: "auto",
                    "&:hover": { background: "#D55112" },
                  }}
                >
                  Quero ser aluno
                </Button>
              </a>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default WhatYouWillReceive;
