import React from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

const PDFViewer = () => (
  <div>
    <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.10.111/pdf.worker.min.js">
      <Viewer fileUrl="Politica.pdf" />
    </Worker>
  </div>
);

export default PDFViewer;
