import React, { useEffect, useState } from "react";
import { Footer } from "./components";
import { makeStyles } from "@mui/styles";
import { Typography, Button, Grid } from "@mui/material";

import rev3ctLogo from "./assets/imgs/Rev3ctLogo.png";
import bannerForm from "./assets/imgs/banner-form.png";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    background: "rgba(0, 0, 0, 0.75)",
    padding: "24px 27px",
    "& img": {
      display: "block",
      margin: "auto",

      width: "220px"
    }
  },
  centerText: {
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)"
    }
  },
  form: {
    background: "#141414",
    [theme.breakpoints.up("md")]: {
      padding: "32px 128px"
    }
  }
}));

const TreinamentoText = () => {
  const classes = useStyles();
  return (
    <div className={classes.centerText}>
      <Typography
        sx={{
          fontFamily: "drukwide",
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "32px",
          color: "#fff",
          marginBottom: "8px"
        }}
      >
        PLANO <br /> WHITE
      </Typography>
      <Typography
        sx={{
          fontFamily: "poppins",
          fontStyle: "normal",
          fontSize: "16px",
          color: "#fff",
          "& span": {
            fontWeight: "500"
          }
        }}
      >
        Olá! Você está a um passo de iniciar de fato sua transformação.
      </Typography>
      <Typography
        sx={{
          fontFamily: "poppins",
          fontStyle: "normal",
          fontSize: "16px",
          color: "#fff",
          "& span": {
            fontWeight: "500"
          }
        }}
      >
        Para isso você precisará preencher esse formulário para gerar o seu
        treino personalizado!
      </Typography>
      <Typography
        sx={{
          fontFamily: "poppins",
          fontStyle: "normal",
          fontSize: "16px",
          color: "#fff",
          "& span": {
            fontWeight: "500"
          }
        }}
      >
        Vamos lá?
      </Typography>
    </div>
  );
};

const FeedbackText = () => {
  const classes = useStyles();
  return (
    <div className={classes.centerText}>
      <Typography
        sx={{
          fontFamily: "drukwide",
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "32px",
          color: "#fff",
          marginBottom: "8px"
        }}
      >
        FEEDBACK <br /> QUINZENAL
      </Typography>
      <Typography
        sx={{
          fontFamily: "poppins",
          fontStyle: "normal",
          fontSize: "16px",
          color: "#fff",
          "& span": {
            fontWeight: "500"
          }
        }}
      >
        Olá!!! Chegou o dia de conferirmos como você está!
      </Typography>
      <Typography
        sx={{
          fontFamily: "poppins",
          fontStyle: "normal",
          fontSize: "16px",
          color: "#fff",
          "& span": {
            fontWeight: "500"
          }
        }}
      >
        Bora lá?
      </Typography>
    </div>
  );
};

const perguntasFeedback = [
  {
    question: "Nome completo:",
    type: "text"
  },
  {
    question: "Qual professor prescreve o seu protocolo de treinamento?",
    type: "options",
    options: ["Alexandre", "Letícia", "Felipe"]
  },
  {
    question:
      "Quais eram os seus principais objetivos quando realizou a última consultoria conosco?",
    type: "text"
  },
  {
    question:
      "De 0 a 10, qual é o seu nível de aderência com o protocolo de treinamento prescrito?",
    type: "rate"
  },
  {
    question:
      "Você percebeu alguma adaptação positiva ou negativa em seu corpo com os treinos executados até o momento? ",
    type: "options",
    options: [
      "Sim, observei aumento de força.",
      "Sim, observei um aumento de massa muscular.",
      "Sim, observei um aumento de força e massa muscular.",
      "Sim, observei uma redução de gordura corporal.",
      "Sim, observei aumento de massa muscular e redução de gordura corporal.",
      "Não observei adaptações no meu corpo."
    ]
  },
  {
    question:
      "Você percebeu alguma mudança em sua disposição para executar diferentes tarefas durante o dia?",
    type: "options",
    options: [
      "Me sinto MAIS disposto para realizar minhas tarefas.",
      "A minha disposição continua a mesma.",
      "Me sinto MENOS disposto para realizar minhas tarefas."
    ]
  },
  {
    question:
      "Você percebeu alguma mudança em sua saúde mental decorrente dos treinos executados até o momento?",
    type: "options",
    options: [
      "A minha saúde mental MELHOROU.",
      "A minha saúde mental PERMANECEU a mesma.",
      "A minha saúde mental PIOROU."
    ]
  },
  {
    question:
      "Caso já tenha reportado antigas restrições articulares, musculares ou tendíneas, você sentiu que as suas dores aumentaram, diminuíram ou permanecem no mesmo nível que anteriormente?",
    type: "text"
  },
  {
    question:
      "Alguma dor muscular, articular ou tendínea não reportada anteriormente surgiu após o início do protocolo de treinamento?",
    type: "text"
  },
  {
    question: "Você removeria algum exercício do seu protocolo? Qual?",
    type: "text"
  },
  {
    question: "Você adicionaria algum exercício em seu protocolo? Qual?",
    type: "text"
  },
  {
    question:
      "Você conseguiu treinar com a frequência estipulada em seu protocolo?",
    type: "text"
  },
  {
    question: "Os treinos estão com uma duração adequada?",
    type: "text"
  },
  {
    question:
      "Você respeitou o tempo de descanso entre as séries e exercícios nos treinos?",
    type: "options",
    options: ["Sim", "Não", "Mais ou menos"]
  },
  {
    question:
      "Caso o professor tenha te orientado a progredir os pesos utilizados nos exercícios, você conseguiu realizar tais progressões nas últimas semanas? Em quais exercícios encontrou maior dificuldade?",
    type: "text"
  },
  {
    question: "Você alteraria algo específico em seu protocolo de treinamento?",
    type: "text"
  },
  {
    question:
      "Você seguiu uma dieta prescrita por um nutricionista durante as últimas semanas?",
    type: "text"
  },
  {
    question:
      "De 0 a 10, como você avalia a qualidade da sua alimentação nas últimas semanas?",
    type: "rate"
  },
  {
    question:
      "Você possui dúvidas, dificuldades, observações ou pedidos especiais em relação ao seu protocolo de treinamento?",
    type: "text"
  }
];
const perguntasTreinos = [
  {
    question: "Nome completo:",
    type: "text"
  },
  {
    question: "Idade:",
    type: "text"
  },
  {
    question: "Quais são os seus principais objetivos?",
    type: "options",
    options: ["Perder peso", "Definir", "Ganhar massa magra", "Hipertrofiar"]
  },
  {
    question: "Possui lesões ósseas?",
    type: "text"
  },
  {
    question: "Possui lesões musculares?",
    type: "text"
  },
  {
    question: "Possui lesões articulares?",
    type: "text"
  },
  {
    question: "Possui doenças crônico-degenerativas?",
    type: "text"
  },
  {
    question: "Qual é o seu nível de treinamento?",
    type: "options",
    options: ["Iiciante", "Intermediário", "Avançado"]
  },
  {
    question:
      "Se você já pratica atividade física, quantos treinos você realiza por semana?",
    type: "text"
  },
  {
    question:
      "Se você já pratica atividade física, quantos exercícios você realiza, em média, por treino?",
    type: "text"
  },
  {
    question: "Pretende treinar em qual ambiente (academia / casa)?",
    type: "options",
    options: ["Academia", "Academia condomínio", "Casa"]
  },
  {
    question: "Qual modelo de treino você prefere?",
    type: "options",
    options: ["Musculação", "Aeróbio", "Misto"]
  },
  {
    question: "Quais músculos mais deseja desenvolver?",
    type: "text"
  },
  {
    question: "Quantas vezes consegue treinar por semana?",
    type: "options",
    options: ["1", "2", "3", "4", "5", "6", "7"]
  },
  {
    question: "Quanto tempo tem disponível para treinar?",
    type: "options",
    options: [
      "30 Minutos",
      "60 Minutos",
      "90 Minutos",
      "120 Minutos",
      "150 Minutos",
      "Acima de 150 Minutos"
    ]
  },
  {
    question: "Quais grupamentos mais gosta de realizar?",
    type: "select",
    options: [
      "Abdomen",
      "Antebraco",
      "Biceps",
      "Deltoides",
      "Dorsal",
      "Lombar",
      "Panturrilha",
      "Peitoral",
      "Posterior/Gluteo",
      "Quadriceps",
      "Triceps"
    ]
  },
  {
    question: "Quais exercícios de força mais gosta de realizar?",
    type: "select",
    options: [
      "Abdominal Reto Solo",
      "Abdominal Remador",
      "Abdominal Na Maquina",
      "Abdominal Tesoura",
      "Abdominal Flexionando E Estendendo Os Joelhos",
      "Abdominal Inferior Vela",
      " Abdominal Na Prancha",
      "Prancha Isometrica",
      "Abdominal Inferior Vertical (Paralela)",
      "Abdominal Obliquo Alternado",
      "Abdominal Canivete",
      " Abdominal Canivete Alternado",
      "Abdominal Com Os Pes Presos No Espaldar",
      "Prancha Isometrica Lateral",
      " Abdominal Canoa Isometrico",
      "Abdominal Vela Alternado",
      "Extensao De Punho Com Barra Na Polia",
      "Flexao De Punho Com Barra Na Polia",
      "Extensao De Punho Com Barra Sentado",
      "Flexao De Punho Com Barra Sentado",
      "Extensao De Punho Com Halter (Unilateral)",
      "Flexao d Punho Com Halter (Unilateral)",
      "Rosca Direta Com Halteres",
      "Rosca Alternada Com Halteres",
      "Rosca Direta Barra W",
      "Rosca Direta Barra Reta",
      "Rosca Direta Barra H",
      "Rosca Arnold",
      "Rosca Concentrada (Unilateral)",
      "Rosca Martelo Com Halteres",
      "Rosca Direta Na Polia Com Barra",
      "Rosca Martelo Na Polia (Corda)",
      "Rosca Scott Com Barra Reta",
      "Rosca Scott Com Barra W",
      "Rosca Scott Maquina",
      "Rosca Direta Com Halteres No Banco Inclinado",
      "Rosca Inversa Com Halteres",
      "Rosca Inversa Com Barra Reta",
      "Rosca Inversa Com Barra Na Polia",
      "Rosca Direta Unilateral Na Polia",
      "Rosca Martelo Unilateral Na Polia",
      "Rosca Martelo Alternada Com Halteres",
      "Rosca Martelo No Banco 90 Graus",
      "Rosca Direta No Banco 90 Graus",
      "Rosca Scott Com Halter Unilateral",
      " Rosca Scott Com Halteres",
      " Rosca Unilateral Na Polia Alta",
      "Rosca Bilateral Na Polia Alta",
      "Elevacao Lateral Com Halteres",
      "Elevacao Frontal Com Halteres",
      "Elevacao Frontal Com Barra",
      "Desenvolvimento De Ombros Com Halteres",
      "Desenvolvimento De Ombros Arnold",
      "Desenvolvimento De Ombros Maquina (Pronada)",
      "Desenvolvimento De Ombros Maquina (Neutra)",
      "Desenvolvimento De Ombros Com Barra",
      "Desenvolvimento De Ombros No Smith",
      " Encolhimento De Ombros Com Halteres",
      "Encolhimento De Ombros Smith",
      "Remada Alta Com Barra Na Polia (Pegada Aberta)",
      "Remada Alta Com Halteres",
      "Manguito Rotador Externo Na Polia",
      "Manguito Rotador Interno Na Polia",
      " Voador Invertido Maquina",
      "Voador Invertido Com Halteres",
      "Elevacao Lateral Unilateral Com Halter",
      "Elevacao Lateral Unilateral Na Polia",
      "Elevacao Lateral Com Halteres(Sentado No Banco Inclinado)",
      "Elevacao Frontal Com Corda Na Polia",
      "Elevacao Frontal Com Barra Na Polia",
      "Elevacao Frontal Unilateral Com Halter",
      "Elevacao Frontal Com Anilha (Pegada Neutra)",
      "Elevacao Frontal Alternada Com Halteres",
      "Elevacao Lateral Maquina",
      "Elevacao Diagonal Com Halteres",
      "Elevacao Lateral Com Halter (Deitado No Banco)",
      "Remada Alta Unilateral Na Polia",
      "Remada Alta Com Barra",
      "Manguito Rotador Externo Elastico",
      "Manguito Rotador Interno Elastico",
      "Voador Invertido No Cabo (Cruzando As Maos)",
      "Voador Invertido No Cabo (Unilateral)",
      "Barra Fixa Com Pegada Supinada",
      "Barra Fixa Com Pegada Neutra",
      " Barra Fixa Com Pegada Pronada E Aberta",
      "Barra Fixa Com Pegada Pronada E Fechada",
      "Pulley Frente Com Barra Comprida (Pronada E Aberta)",
      "Pulley Frente Com Triangulo (Neutra E Fechada)",
      "Pulley Frente Com Barra (Pegada Supinada)",
      "Pulley Frente Unilateral",
      "Pull Down Com Barra Na Polia",
      "Pull Down Com Corda Na Polia",
      "Remada Cavalo Maquina (Pegada Pronada)",
      "Remada Cavalo Maquina (Pegada Neutra)",
      "Remada Cavalo Barra Livre (Pegada Neutra)",
      "Remada Curvada Com Barra Pegada Supinada",
      "Remada Curvada Com Barra Pegada Pronada E Aberta",
      "Remada Curvada Com Barra Pegada Pronada E Fechada",
      "Remada Baixa Com Triangulo Pegada Neutra",
      "Remada Baixa Com Barra Pegada Pronada E Aberta",
      "Remada Baixa Com Barra Pegada Supinada",
      "Remada Baixa Com Barra Pegada Neutra E Aberta",
      "Remada Unilateral Com Halter (Ombro Aduzido)",
      "Remada Unilateral Com Halter (Ombro Abduzido)",
      "Remada Maquina Pegada Neutra",
      "Remada Maquina Pegada Pronada",
      "Remada Articualda Pegada Neutra",
      "Remada Articualda Pegada Pronada",
      "Remada Na Polia Alta Com Corda (Ombros Abduzidos)",
      "Levantamento Terra Romeno",
      "Barra Fixa No Graviton Com Pegada Supinada",
      "Barra Fixa No Graviton Com Pegada Neutra",
      "Barra Fixa No Graviton Com Pegada Pronada E Aberta",
      "Puxador Articulado Pegada Pronada",
      "Puxador Articulado Pegada Supinada",
      " Pull Down Com Barra Grande Na Polia",
      "Pull Down Unilateral No Cabo",
      "Pull Over Com Halter",
      "Remada Curvada Na Polia Baixa",
      "Remada Curvada Com Halteres Pegada Neutra",
      "Remada Curvada Com Halteres Pegada Pronada",
      "Remada Curvada Com Halteres Pegada Supinada",
      "Remada Curvada Com O Peito Apoiado No Banco Pegada Neutra",
      "Remada Curvada Com O Peito Apoiado No Banco Pegada Pronada",
      "Remada Curvada No Smith Pegada Pronada",
      "Remada Curvada No Smith Pegada Supinada",
      "Remada Baixa Unilateral",
      "Pulley Frente Com Barra Pegada Neutra E Aberta",
      "Remada Maquina Unilateral Pegada Neutra",
      " Remada Maquina Unilateral Pegada Pronada",
      "Remada Articulada Unilateral Pegada Neutra",
      "Remada Articulada Unilateral Pegada Pronada",
      "Extensao Lombar No Banco Romano",
      "Extensao Lombar Isometrica",
      "Extensao Lombar Dinamico (Solo)",
      " Levantamento Terra Romeno",
      "Panturrilha Vertical Maquina",
      " Panturrilha Sentado Maquina",
      " Panturrilha Step",
      "Panturrilha No Leg Press 45 Graus",
      "Panturrilha No Leg Press Horizontal",
      "Panturrilha No Hack Horizontal",
      "Panturrilha Vertical Unilateral Maquina",
      "Panturrilha Unilateral Sentado Maquina",
      "Panturrilha Unilateral No Step",
      "Panturrilha Unilateral No Leg Press 45 Graus",
      "Panturrilha Unilateral No Leg Press Horizontal",
      "Panturrilha Unilateral No Hack Horizontal",
      " Supino Reto",
      "Supino Inclinado",
      "Press Com Halteres No Banco Reto",
      "Press Com Halteres No Banco Inclinado",
      "Peck Deck",
      "Crucifixo Com Halteres",
      "Cross Over Na Polia Alta",
      "Cross Over Na Polia Media",
      "Cross Over Na Polia Baixa",
      "Supino Maquina",
      "Supino Reto Articulado",
      "Supino Inclinado Articulado",
      "Fly",
      "Crucifixo No Cabo (Cruzando As Maos)",
      "Press Com Giro",
      "Press Com Pegada Neutra",
      "Supino Reto No Smith",
      "Supino Inclinado No Smith",
      "Supino Com Pegada Fechada",
      "Supino Declinado",
      "Flexao De Bracos Com As Maos Afastadas E Joelhos Apoiados",
      " Flexao De Bracos Fechada Com Os Pes Apoiados",
      "Flexao De Bracos Fechada Com Os Joelhos Apoiados",
      "Flexao De Bracos Com As Maos Afastadas",
      "Flexao De Bracos No Smith",
      "Stiff Com Barra",
      "Cadeira Flexora",
      "Mesa Flexora",
      "Elevacao Pelvica Barra (Escapulas Apoiadas No Banco)",
      " Flexora Vertical",
      "Gluteo Maquina",
      "Gluteo Na Polia Perna Estendida",
      "Cadeira Abdutora",
      "Abducao De Quadril Na Polia",
      "Elevacao Pelvica Maquina",
      "Elevacao Pelvica Halter (Escapulas Apoiadas No Banco)",
      "Leg Press 45 Graus (Pes Na Regiao Superior Da Plataforma)",
      "Passada Com Halteres",
      "Gluteo Coice Na Polia",
      "Levantamento Terra Tradicional",
      "Levantamento Terra Sumo",
      "Stiff Com Halteres",
      "Cadeira Flexora Unilateral",
      "Mesa Flexora Unilateral",
      "Elevacao Pelvica No Chao",
      "Elevacao Pelvica Barra Unilateral (Escapulas Apoiadas No Banco)",
      "Elevacao Pelvica Unilateral No Chao",
      "Glúteo Caneleira Com A Perna Estendida",
      "Glúteo Caneleira Com A Perna Flexionada",
      "Step Up Com Halter",
      "Elevacao Pelvica Maquina Unilateral",
      "Elevacao Pelvica Halter Unilateral (Escapulas Apoiadas No Banco)",
      "Stiff Unilateral Com Halter",
      "Abducao De Quadril Com Caneleira",
      "Agachamento Livre Com Barra",
      "Agachamento Tradicional No Smith",
      "Agachamento Frontal Com Barra",
      "Afundo Unilateral Com Halteres",
      "Afundo No Smith",
      "Cadeira Extensora",
      "Leg Press Horizontal",
      "Hack Horizontal",
      "Agachamento No Hack",
      "Leg Press 45 Graus",
      "Agachamento Sumo Com Halter",
      "Afundo Bulgaro",
      "Agachamento Com Halteres",
      "Agachamento Frontal Com Halter",
      "Avanco Unilateral Com Halteres",
      "Avanco Alternado Com Halteres",
      "Cadeira Extensora Unilateral",
      "Leg Press Horizontal Unilateral",
      "Hack Horizontal Unilateral",
      " Leg Press 45 Graus Unilateral",
      "Agachamento Sumô Com Barra",
      "Levantamento Terra Sumô",
      "Cadeira Isometrica",
      "Recuo Unilateral Com Halteres",
      "Recuo Unilateral No Smith",
      "Rosca Francesa Unilateral Com Halter",
      "Rosca Francesa Bilateral Com Halter",
      "Rosca Francesa Na Polia Com Corda",
      "Triceps Barra Na Polia",
      "Triceps Corda Na Polia",
      "Triceps Unilateral No Cabo",
      "Triceps Coice No Cabo (Unilateral Pegada Supinada)",
      " Triceps Coice No Cabo (Unilateral Pegada Neutra)",
      "Triceps Maquina (Paralelas)",
      "Triceps Testa Com Barra W",
      "Triceps Invertido Na Polia Com Barra",
      "Triceps Coice Unilateral Com Halter",
      "Triceps Coice Bilateral Com Halteres",
      "Triceps Na Barra Paralela",
      "Triceps Testa Com Halter",
      "Triceps Testa Alternado Com Halteres",
      "Supino Fechado Com Halteres (Pegada Neutra)",
      "Supino Fechado Com Barra",
      "Triceps Testa Na Polia Alta Com Barra",
      "Triceps Testa Na Polia Alta Com Corda",
      "Extensao De Triceps Maquina"
    ]
  },
  {
    question: "Quais exercícios de força não gosta de realizar?",
    type: "select",
    options: [
      "Abdominal Reto Solo",
      "Abdominal Remador",
      "Abdominal Na Maquina",
      "Abdominal Tesoura",
      "Abdominal Flexionando E Estendendo Os Joelhos",
      "Abdominal Inferior Vela",
      " Abdominal Na Prancha",
      "Prancha Isometrica",
      "Abdominal Inferior Vertical (Paralela)",
      "Abdominal Obliquo Alternado",
      "Abdominal Canivete",
      " Abdominal Canivete Alternado",
      "Abdominal Com Os Pes Presos No Espaldar",
      "Prancha Isometrica Lateral",
      " Abdominal Canoa Isometrico",
      "Abdominal Vela Alternado",
      "Extensao De Punho Com Barra Na Polia",
      "Flexao De Punho Com Barra Na Polia",
      "Extensao De Punho Com Barra Sentado",
      "Flexao De Punho Com Barra Sentado",
      "Extensao De Punho Com Halter (Unilateral)",
      "Flexao d Punho Com Halter (Unilateral)",
      "Rosca Direta Com Halteres",
      "Rosca Alternada Com Halteres",
      "Rosca Direta Barra W",
      "Rosca Direta Barra Reta",
      "Rosca Direta Barra H",
      "Rosca Arnold",
      "Rosca Concentrada (Unilateral)",
      "Rosca Martelo Com Halteres",
      "Rosca Direta Na Polia Com Barra",
      "Rosca Martelo Na Polia (Corda)",
      "Rosca Scott Com Barra Reta",
      "Rosca Scott Com Barra W",
      "Rosca Scott Maquina",
      "Rosca Direta Com Halteres No Banco Inclinado",
      "Rosca Inversa Com Halteres",
      "Rosca Inversa Com Barra Reta",
      "Rosca Inversa Com Barra Na Polia",
      "Rosca Direta Unilateral Na Polia",
      "Rosca Martelo Unilateral Na Polia",
      "Rosca Martelo Alternada Com Halteres",
      "Rosca Martelo No Banco 90 Graus",
      "Rosca Direta No Banco 90 Graus",
      "Rosca Scott Com Halter Unilateral",
      " Rosca Scott Com Halteres",
      " Rosca Unilateral Na Polia Alta",
      "Rosca Bilateral Na Polia Alta",
      "Elevacao Lateral Com Halteres",
      "Elevacao Frontal Com Halteres",
      "Elevacao Frontal Com Barra",
      "Desenvolvimento De Ombros Com Halteres",
      "Desenvolvimento De Ombros Arnold",
      "Desenvolvimento De Ombros Maquina (Pronada)",
      "Desenvolvimento De Ombros Maquina (Neutra)",
      "Desenvolvimento De Ombros Com Barra",
      "Desenvolvimento De Ombros No Smith",
      " Encolhimento De Ombros Com Halteres",
      "Encolhimento De Ombros Smith",
      "Remada Alta Com Barra Na Polia (Pegada Aberta)",
      "Remada Alta Com Halteres",
      "Manguito Rotador Externo Na Polia",
      "Manguito Rotador Interno Na Polia",
      " Voador Invertido Maquina",
      "Voador Invertido Com Halteres",
      "Elevacao Lateral Unilateral Com Halter",
      "Elevacao Lateral Unilateral Na Polia",
      "Elevacao Lateral Com Halteres(Sentado No Banco Inclinado)",
      "Elevacao Frontal Com Corda Na Polia",
      "Elevacao Frontal Com Barra Na Polia",
      "Elevacao Frontal Unilateral Com Halter",
      "Elevacao Frontal Com Anilha (Pegada Neutra)",
      "Elevacao Frontal Alternada Com Halteres",
      "Elevacao Lateral Maquina",
      "Elevacao Diagonal Com Halteres",
      "Elevacao Lateral Com Halter (Deitado No Banco)",
      "Remada Alta Unilateral Na Polia",
      "Remada Alta Com Barra",
      "Manguito Rotador Externo Elastico",
      "Manguito Rotador Interno Elastico",
      "Voador Invertido No Cabo (Cruzando As Maos)",
      "Voador Invertido No Cabo (Unilateral)",
      "Barra Fixa Com Pegada Supinada",
      "Barra Fixa Com Pegada Neutra",
      " Barra Fixa Com Pegada Pronada E Aberta",
      "Barra Fixa Com Pegada Pronada E Fechada",
      "Pulley Frente Com Barra Comprida (Pronada E Aberta)",
      "Pulley Frente Com Triangulo (Neutra E Fechada)",
      "Pulley Frente Com Barra (Pegada Supinada)",
      "Pulley Frente Unilateral",
      "Pull Down Com Barra Na Polia",
      "Pull Down Com Corda Na Polia",
      "Remada Cavalo Maquina (Pegada Pronada)",
      "Remada Cavalo Maquina (Pegada Neutra)",
      "Remada Cavalo Barra Livre (Pegada Neutra)",
      "Remada Curvada Com Barra Pegada Supinada",
      "Remada Curvada Com Barra Pegada Pronada E Aberta",
      "Remada Curvada Com Barra Pegada Pronada E Fechada",
      "Remada Baixa Com Triangulo Pegada Neutra",
      "Remada Baixa Com Barra Pegada Pronada E Aberta",
      "Remada Baixa Com Barra Pegada Supinada",
      "Remada Baixa Com Barra Pegada Neutra E Aberta",
      "Remada Unilateral Com Halter (Ombro Aduzido)",
      "Remada Unilateral Com Halter (Ombro Abduzido)",
      "Remada Maquina Pegada Neutra",
      "Remada Maquina Pegada Pronada",
      "Remada Articualda Pegada Neutra",
      "Remada Articualda Pegada Pronada",
      "Remada Na Polia Alta Com Corda (Ombros Abduzidos)",
      "Levantamento Terra Romeno",
      "Barra Fixa No Graviton Com Pegada Supinada",
      "Barra Fixa No Graviton Com Pegada Neutra",
      "Barra Fixa No Graviton Com Pegada Pronada E Aberta",
      "Puxador Articulado Pegada Pronada",
      "Puxador Articulado Pegada Supinada",
      " Pull Down Com Barra Grande Na Polia",
      "Pull Down Unilateral No Cabo",
      "Pull Over Com Halter",
      "Remada Curvada Na Polia Baixa",
      "Remada Curvada Com Halteres Pegada Neutra",
      "Remada Curvada Com Halteres Pegada Pronada",
      "Remada Curvada Com Halteres Pegada Supinada",
      "Remada Curvada Com O Peito Apoiado No Banco Pegada Neutra",
      "Remada Curvada Com O Peito Apoiado No Banco Pegada Pronada",
      "Remada Curvada No Smith Pegada Pronada",
      "Remada Curvada No Smith Pegada Supinada",
      "Remada Baixa Unilateral",
      "Pulley Frente Com Barra Pegada Neutra E Aberta",
      "Remada Maquina Unilateral Pegada Neutra",
      " Remada Maquina Unilateral Pegada Pronada",
      "Remada Articulada Unilateral Pegada Neutra",
      "Remada Articulada Unilateral Pegada Pronada",
      "Extensao Lombar No Banco Romano",
      "Extensao Lombar Isometrica",
      "Extensao Lombar Dinamico (Solo)",
      " Levantamento Terra Romeno",
      "Panturrilha Vertical Maquina",
      " Panturrilha Sentado Maquina",
      " Panturrilha Step",
      "Panturrilha No Leg Press 45 Graus",
      "Panturrilha No Leg Press Horizontal",
      "Panturrilha No Hack Horizontal",
      "Panturrilha Vertical Unilateral Maquina",
      "Panturrilha Unilateral Sentado Maquina",
      "Panturrilha Unilateral No Step",
      "Panturrilha Unilateral No Leg Press 45 Graus",
      "Panturrilha Unilateral No Leg Press Horizontal",
      "Panturrilha Unilateral No Hack Horizontal",
      " Supino Reto",
      "Supino Inclinado",
      "Press Com Halteres No Banco Reto",
      "Press Com Halteres No Banco Inclinado",
      "Peck Deck",
      "Crucifixo Com Halteres",
      "Cross Over Na Polia Alta",
      "Cross Over Na Polia Media",
      "Cross Over Na Polia Baixa",
      "Supino Maquina",
      "Supino Reto Articulado",
      "Supino Inclinado Articulado",
      "Fly",
      "Crucifixo No Cabo (Cruzando As Maos)",
      "Press Com Giro",
      "Press Com Pegada Neutra",
      "Supino Reto No Smith",
      "Supino Inclinado No Smith",
      "Supino Com Pegada Fechada",
      "Supino Declinado",
      "Flexao De Bracos Com As Maos Afastadas E Joelhos Apoiados",
      " Flexao De Bracos Fechada Com Os Pes Apoiados",
      "Flexao De Bracos Fechada Com Os Joelhos Apoiados",
      "Flexao De Bracos Com As Maos Afastadas",
      "Flexao De Bracos No Smith",
      "Stiff Com Barra",
      "Cadeira Flexora",
      "Mesa Flexora",
      "Elevacao Pelvica Barra (Escapulas Apoiadas No Banco)",
      " Flexora Vertical",
      "Gluteo Maquina",
      "Gluteo Na Polia Perna Estendida",
      "Cadeira Abdutora",
      "Abducao De Quadril Na Polia",
      "Elevacao Pelvica Maquina",
      "Elevacao Pelvica Halter (Escapulas Apoiadas No Banco)",
      "Leg Press 45 Graus (Pes Na Regiao Superior Da Plataforma)",
      "Passada Com Halteres",
      "Gluteo Coice Na Polia",
      "Levantamento Terra Tradicional",
      "Levantamento Terra Sumo",
      "Stiff Com Halteres",
      "Cadeira Flexora Unilateral",
      "Mesa Flexora Unilateral",
      "Elevacao Pelvica No Chao",
      "Elevacao Pelvica Barra Unilateral (Escapulas Apoiadas No Banco)",
      "Elevacao Pelvica Unilateral No Chao",
      "Glúteo Caneleira Com A Perna Estendida",
      "Glúteo Caneleira Com A Perna Flexionada",
      "Step Up Com Halter",
      "Elevacao Pelvica Maquina Unilateral",
      "Elevacao Pelvica Halter Unilateral (Escapulas Apoiadas No Banco)",
      "Stiff Unilateral Com Halter",
      "Abducao De Quadril Com Caneleira",
      "Agachamento Livre Com Barra",
      "Agachamento Tradicional No Smith",
      "Agachamento Frontal Com Barra",
      "Afundo Unilateral Com Halteres",
      "Afundo No Smith",
      "Cadeira Extensora",
      "Leg Press Horizontal",
      "Hack Horizontal",
      "Agachamento No Hack",
      "Leg Press 45 Graus",
      "Agachamento Sumo Com Halter",
      "Afundo Bulgaro",
      "Agachamento Com Halteres",
      "Agachamento Frontal Com Halter",
      "Avanco Unilateral Com Halteres",
      "Avanco Alternado Com Halteres",
      "Cadeira Extensora Unilateral",
      "Leg Press Horizontal Unilateral",
      "Hack Horizontal Unilateral",
      " Leg Press 45 Graus Unilateral",
      "Agachamento Sumô Com Barra",
      "Levantamento Terra Sumô",
      "Cadeira Isometrica",
      "Recuo Unilateral Com Halteres",
      "Recuo Unilateral No Smith",
      "Rosca Francesa Unilateral Com Halter",
      "Rosca Francesa Bilateral Com Halter",
      "Rosca Francesa Na Polia Com Corda",
      "Triceps Barra Na Polia",
      "Triceps Corda Na Polia",
      "Triceps Unilateral No Cabo",
      "Triceps Coice No Cabo (Unilateral Pegada Supinada)",
      " Triceps Coice No Cabo (Unilateral Pegada Neutra)",
      "Triceps Maquina (Paralelas)",
      "Triceps Testa Com Barra W",
      "Triceps Invertido Na Polia Com Barra",
      "Triceps Coice Unilateral Com Halter",
      "Triceps Coice Bilateral Com Halteres",
      "Triceps Na Barra Paralela",
      "Triceps Testa Com Halter",
      "Triceps Testa Alternado Com Halteres",
      "Supino Fechado Com Halteres (Pegada Neutra)",
      "Supino Fechado Com Barra",
      "Triceps Testa Na Polia Alta Com Barra",
      "Triceps Testa Na Polia Alta Com Corda",
      "Extensao De Triceps Maquina"
    ]
  },
  {
    question: "Quais exercícios aeróbios mais gosta de realizar?",
    type: "select",
    options: ["Esteira", "Bicicleta", "Escada", "Elítico"]
  },
  {
    question: "Quais exercícios aeróbios não gosta de realizar?",
    type: "select",
    options: ["Esteira", "Bicicleta", "Escada", "Elítico"]
  }
];

const rate = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

export default function Form(props) {
  const classes = useStyles();
  const [perguntas, setPerguntas] = useState([]);

  useEffect(() => {
    props.formulario === "treinos"
      ? setPerguntas(perguntasTreinos)
      : setPerguntas(perguntasFeedback);
  }, [props.formulario]);
  return (
    <div>
      <header className={classes.header}>
        <img src={rev3ctLogo} alt="Rev3ct" />
      </header>
      <Grid container>
        <Grid
          item
          sm={12}
          md={6}
          sx={{ background: "#000", padding: "16px", position: "relative" }}
        >
          {props.formulario === "treinos" ? (
            <TreinamentoText />
          ) : (
            <FeedbackText />
          )}
        </Grid>
        <Grid item sm={12} md={6}>
          <img width="100%" src={bannerForm} alt="Rev3ct" />
        </Grid>
      </Grid>
      <div className={classes.form}>
        {perguntas.map((i, index) => {
          switch (i.type) {
            case "text":
              return (
                <div key={index}>
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontStyle: "normal",
                      fontSize: "16px",
                      color: "#fff",
                      marginBottom: "32px",
                      "& span": {
                        fontWeight: "500"
                      }
                    }}
                  >
                    {i.question}
                  </Typography>
                  <input
                    type="text"
                    placeholder="Sua resposta"
                    fullWidth
                    style={{
                      width: "100%",
                      marginBottom: "32px",
                      color: "#D9D9D9",
                      padding: "8px",
                      background: "transparent",
                      border: "1px solid white",
                      "&::placeholder": {
                        color: "#D9D9D9",
                        fontFamily: "poppins"
                      }
                    }}
                  />
                  <br />
                </div>
              );
            case "options":
              return (
                <div key={index}>
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontStyle: "normal",
                      fontSize: "16px",
                      color: "#fff",
                      marginBottom: "32px",
                      "& span": {
                        fontWeight: "500"
                      }
                    }}
                  >
                    {i.question}
                  </Typography>
                  {i.options.map((j, index) => {
                    return (
                      <>
                        <input type="radio" id={j} name="" value="" />
                        <label
                          style={{
                            marginLeft: "8px",
                            fontFamily: "poppins",
                            fontStyle: "normal",
                            fontSize: "14px",
                            color: "#D9D9D9",
                            "& span": {
                              fontWeight: "500"
                            }
                          }}
                          for={j}
                        >
                          {j}
                        </label>
                        <br />
                        <br />
                      </>
                    );
                  })}
                  <br />
                </div>
              );
            case "rate":
              return (
                <div key={index}>
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontStyle: "normal",
                      fontSize: "16px",
                      color: "#fff",
                      marginBottom: "32px",
                      "& span": {
                        fontWeight: "500"
                      }
                    }}
                  >
                    {i.question}
                  </Typography>
                  <div style={{ display: "flex" }}>
                    {rate.map((j, index) => {
                      return (
                        <>
                          <input type="radio" id={j} name="" value="" />
                          <label
                            style={{
                              marginLeft: "8px",
                              marginRight: "8px",
                              fontFamily: "poppins",
                              fontStyle: "normal",
                              fontSize: "14px",
                              color: "#D9D9D9",
                              "& span": {
                                fontWeight: "500"
                              }
                            }}
                            for={j}
                          >
                            {j}
                          </label>

                          <br />
                          <br />
                        </>
                      );
                    })}
                  </div>
                  <br />
                </div>
              );
            case "select":
              return (
                <div key={index}>
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontStyle: "normal",
                      fontSize: "16px",
                      color: "#fff",
                      marginBottom: "32px",
                      "& span": {
                        fontWeight: "500"
                      }
                    }}
                  >
                    {i.question}
                  </Typography>
                  {i.options.map((j, index) => {
                    return (
                      <>
                        <input type="checkbox" id={j} name="" value="" />
                        <label
                          style={{
                            marginLeft: "8px",
                            fontFamily: "poppins",
                            fontStyle: "normal",
                            fontSize: "14px",
                            color: "#D9D9D9",
                            "& span": {
                              fontWeight: "500"
                            }
                          }}
                          for={j}
                        >
                          {j}
                        </label>
                        <br />
                      </>
                    );
                  })}
                  <br />
                </div>
              );
            default:
              return <></>;
          }
        })}
      </div>
      <Button
        sx={{
          fontFamily: "drukwide",
          color: "#fff",
          background: "#D55112",
          borderRadius: "18px",
          display: "block",
          margin: "auto",
          "&:hover": { background: "#D55112" }
        }}
      >
        Enviar
      </Button>
      <Footer />
    </div>
  );
}
