import React from "react";
import { makeStyles } from "@mui/styles";
import rev3ctLogo from "../assets/imgs/Rev3ctLogo.png";
import { Typography } from "@mui/material";

const useStyles = makeStyles({
  root: {
    marginTop: "88px",
    "& img": {
      display: "block",
      margin: "auto",
      width: "180px",
    },
  },
});

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img src={rev3ctLogo} alt="Rev3ct" />
      <Typography
        sx={{
          fontFamily: "gotham-thin",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "16px",
          color: "rgba(255, 255, 255, 0.38)",
        }}
        align="center"
      >
        Fale conosco
      </Typography>
      <Typography
        sx={{
          fontFamily: "gotham-thin",
          fontStyle: "normal",
          fontSize: "16px",
          color: "rgba(255, 255, 255, 0.38)",
          marginBottom: "16px",
        }}
        align="center"
      >
        Tel.: (11) 94329-5462
      </Typography>
      <Typography
        sx={{
          fontFamily: "gotham-thin",
          fontStyle: "normal",
          fontSize: "16px",
          color: "rgba(255, 255, 255, 0.38)",
          paddingBottom: "16px",
        }}
        align="center"
      >
        R. Hilda Del Nero Bisquolo, 102 - Sala 101 - Jundiaí - SP - CEP 13208-703
      </Typography>
    </div>
  );
};

export default Footer;
