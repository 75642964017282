import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slider from "react-slick";
import useMediaQuery from "@mui/material/useMediaQuery";

import case1 from "../assets/imgs/case1.png";
import case2 from "../assets/imgs/case2.png";
import case3 from "../assets/imgs/case3.png";
import case4 from "../assets/imgs/case4.png";
import case5 from "../assets/imgs/case5.png";
import case6 from "../assets/imgs/case6.png";
import case7 from "../assets/imgs/case7.png";
import case8 from "../assets/imgs/case8.png";
import case9 from "../assets/imgs/case9.png";
import case10 from "../assets/imgs/case10.png";
import case11 from "../assets/imgs/case11.png";
import case12 from "../assets/imgs/case12.png";
import inicio from "../assets/imgs/inicio.png";
import clock from "../assets/imgs/clock.png";
import fim from "../assets/imgs/fim.png";
import { Arrow } from "./Arrow";

const casePhotos = [
  case1,
  case2,
  case3,
  case4,
  case5,
  case6,
  case7,
  case8,
  case9,
  case10,
  case11,
  case12,
];

const useStyles = makeStyles({
  root: {
    paddingTop: "80px",
    padding: "80px 16px 0px 16px",
  },
  icons: {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px",
  },
  img: {
    display: "block",
    margin: "auto",
    width: "100%",
  },
  container: {
    padding: "4px",
  },
});

const Cases = () => {
  const classes = useStyles();
  const mobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: mobile ? 1 : 4,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <Arrow next />,
    prevArrow: <Arrow />
  };

  return (
    <div className={classes.root}>
      <Typography
        sx={{
          fontFamily: "drukwide",
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "24px",
          textAlign: "center",
          color: "#fff",
          marginBottom: "16px",
        }}
      >
        CASES
      </Typography>
      <Slider {...settings}>
        {casePhotos.map((i, index) => (
          <div key={index} className={classes.container}>
            <Card
              sx={{
                background: "#1F1F1F",
                borderRadius: "24px",
                padding: "8px",
              }}
            >
              <CardContent>
                <img src={i} className={classes.img} alt="Rev3ct" />
                <div className={classes.icons}>
                  <img src={inicio} alt="" />
                  <img src={clock} alt="" />
                  <img src={fim} alt="" />
                </div>
              </CardContent>
            </Card>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Cases;
