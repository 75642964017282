import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slider from "react-slick";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Arrow } from "./Arrow";

const r3vPlan = {
  infos: [
    "Consultoria presencial ou online",
    "12 semanas de duração",
    "+ Benefícios exclusivos",
    "+1 retorno de consultoria",
    "Produtos exclusivos",
  ],
};

const plans = [
  {
    name: "Plano White",
    infos: [
      "Treino por formulário",
      "45 dias de duração",
      "+ Benefícios de um R3Vmember"
    ],
  },
  {
    name: "Plano Orange",
    infos: [
      "Consultoria Online",
      "8 semanas de duração",
	    "+ Benefícios de um R3Vmember"
    ],
  },
  {
    name: "Plano Black",
    infos: [
      "Consultoria Presencial",
	    "8 semanas de duração",
	    "Avaliação física completa",
	    "+ Benefícios de um R3Vmember"
    ],
  },
];

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: "80px",
    padding: "80px 16px 80px 16px",
    background: "#000",
  },
  icons: {
    display: "flex",
    justifyContent: "space-between",
  },
  img: {
    display: "block",
    margin: "auto",
  },
  hr: {
    width: "80%",
    border: "1px solid #fff",
  },
  list: {
    
  },
  li: {
    fontFamily: "poppins",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "25px",
    color: "#fff",
    marginBottom: "16px",
    padding: "0px 24px",
    "& span": {
      fontWeight: "500",
    },
  },
  recommended: {
    background: "#D55112",
    borderRadius: "16px 16px 0px 0px",
    marginBottom: "0px",
    width: "60%",
    display: "block",
    margin: "auto",
    padding: "8px",
    boxShadow: "0px 0px 21px 8px #D55112",
  },
  container: {
    padding: "16px",
    [theme.breakpoints.down(600)]: {
      width: '90%'
    }
  },
  dialog: {
    padding: "8px",
    display: "flex",
    flexDirection: "column",
    fontFamily: "drukwide",
  },
}));

const Plans = () => {
  const classes = useStyles();
  const mobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const [coupon, setCoupon] = useState("");

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: mobile ? 1 : 4,
    slidesToScroll: 1,
    arrows: mobile,
    nextArrow: <Arrow next />,
    prevArrow: <Arrow />
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        sx={{ padding: {
          sm: "24px",
          md: "16px" 
        }}}
      >
        <DialogTitle sx={{ textAlign: "center", fontFamily: "drukwide" }}>
          Tem um cupom?
        </DialogTitle>
        <div className={classes.dialog}>
          <TextField
            placeholder="Digite seu cupom..."
            value={coupon}
            onChange={(e) => setCoupon(e.target.value)}
            sx={{ marginBottom: "8px" }}
          />
          <a
            href={`https://api.whatsapp.com/send?phone=5511943295462&text=Gostaria%20de%20saber%20mais%20sobre%20os%20planos.%20tenho%20o%20cupom%20${coupon}`}
            target="_blank" rel="noreferrer"
          >
            <Button
              variant="contained"
              sx={{
                fontFamily: "drukwide",
                fontSize: "12px",
                color: "#fff",
                background: "#D55112",
                borderRadius: "18px",
                display: "block",
                margin: "auto",
                "&:hover": { background: "#D55112" },
              }}
            >
              Continuar com cupom
            </Button>
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=5511943295462&text=Gostaria%20de%20saber%20mais%20sobre%20os%20planos."
            target="_blank" rel="noreferrer"
          >
            <Button
              sx={{
                textAlign: "center",
                fontFamily: "drukwide",
                fontSize: "10px",
                color: "#000",
                textDecoration: "underline",
                display: "block",
                margin: "auto",
                "&:hover": { textDecoration: "underline", color: "#D55112" },
              }}
            >
              Continuar sem cupom
            </Button>
          </a>
        </div>
      </Dialog>
      <Typography
        sx={{
          fontFamily: "drukwide",
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "24px",
          textAlign: "center",
          color: "#fff",
          marginBottom: "16px",
        }}
      >
        PLANOS
      </Typography>
      <Slider {...settings}>
        <div className={classes.container}>
          <div className={classes.recommended}>
            <Typography
              sx={{
                fontFamily: "drukwide",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "12px",
                textAlign: "center",
                color: "#fff",
              }}
            >
              RECOMENDADO
            </Typography>
          </div>
          <Card
            sx={{
              background: "#1F1F1F",
              borderRadius: "24px",
              padding: "8px",
              height: '100%',
              boxShadow: "0px 0px 21px 8px #D55112",
            }}
          >
            <CardContent >
              <Typography
                sx={{
                  fontFamily: "drukwide",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "28px",
                  textAlign: "center",
                  color: "#fff",
                  marginBottom: "16px",
                }}
              >
                R3V Plan
              </Typography>
              <hr className={classes.hr} />
              <ul className={classes.list}>
                {r3vPlan.infos.map((j, index) => {
                  return <li key={index} className={classes.li}>{j}</li>;
                })}
              </ul>
              <Button
                variant="contained"
                onClick={() => setOpen(true)}
                sx={{
                  fontFamily: "drukwide",
                  color: "#141414",
                  background: "#fff",
                  borderRadius: "18px",
                  display: "block",
                  margin: "auto",
                  "&:hover": { background: "#D55112" },
                }}
              >
                EU QUERO
              </Button>
            </CardContent>
          </Card>
        </div>
        {plans.map((i, index) => (
          <div key={index} className={classes.container}>
            <div className={classes.recommended} style={{ opacity: "0" }}>
              <Typography
                sx={{
                  fontFamily: "drukwide",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                RECOMENDADO 
              </Typography>
            </div>
            <Card
              sx={{
                background: "#1F1F1F",
                borderRadius: "24px",
                padding: "8px",
                height: '100%'
              }}
            >
              <CardContent>
                <Typography
                  sx={{
                    fontFamily: "drukwide",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "28px",
                    textAlign: "center",
                    color: "#fff",
                    marginBottom: "16px",
                  }}
                >
                  {i.name}
                </Typography>
                <hr className={classes.hr} />
                <ul className={classes.list}>
                  {i.infos.map((j, index) => {
                    return <li key={index} className={classes.li}>{j}</li>;
                  })}
                </ul>
                <Button
                  variant="contained"
                  onClick={() => setOpen(true)}
                  sx={{
                    fontFamily: "drukwide",
                    color: "#141414",
                    background: "#fff",
                    borderRadius: "18px",
                    display: "block",
                    margin: "auto",
                    "&:hover": { background: "#D55112" },
                  }}
                >
                  EU QUERO
                </Button>
              </CardContent>
            </Card>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Plans;
