import React from "react";
import {
  Cases,
  Explanation,
  Faq,
  Footer,
  How,
  Mentors,
  Plans,
  Testimonials,
  WhatYouWillReceive
} from "./components";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Form from "./Form";
import AppPolitcs from "./pages/MyDocument";

const Home = () => {
  return (
    <>
      <Explanation />
      <Mentors />
      <WhatYouWillReceive />
      <Testimonials />
      <Cases />
      <How />
      <Plans />
      <Faq />
      <Footer />
    </>
  );
};

const FormTreinos = () => {
  return <Form formulario="treinos" />;
};

const FormFeedback = () => {
  return <Form formulario="feedback" />;
};

function App() {
  return (
    <div className="App" style={{ margin: "0", backgroundColor: "#141414" }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/form-treinos" component={FormTreinos} />
            <Route exact path="/form-feedback" component={FormFeedback} />
            <Route exact path="/politics" component={AppPolitcs}></Route>
            <Route path="*">
              <Home />
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
